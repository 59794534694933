import React from "react";
import * as Sentry from "@sentry/react";

const style = { position: "fixed", bottom: "10px", right: "10px", fontSize: "0.5em" };

const Version = ({ version }) => {

  const sendERROR = () => {
    console.error('send sentry "Hello World" Error');
    Sentry.captureException(new Error("HELLO WORLD"));
  };

  return (
    <div style={style} onDoubleClick={sendERROR}>
      {version}
    </div>
  );
};

export default Version;