import React, {useEffect} from 'react';
import { Trans } from "react-i18next";
import {Paper, Grid, styled} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import RoomIcon from '@mui/icons-material/Room';
import { useTranslation } from "react-i18next";

const bigIconStyle = ({theme}) => ({
    margin: theme.spacing(1),
    fontSize: 76
})

const BigDoneIcon = styled(p => <DoneIcon {...p} />)(bigIconStyle);
const BigFixedIcon = styled(p => <GpsFixedIcon {...p} />)(bigIconStyle);
const BigRoomIcon = styled(p => <RoomIcon {...p} />)(bigIconStyle);

const MyPaper = styled( p => <Paper elevation={0} {...p} />)(({theme}) => ({
    padding: theme.spacing(1),
    textAlign: 'center'
}));

const Root = styled("div")(({theme}) => ({
  padding: theme.spacing(2)
}))

function Descrition() {
  const gridXs = 8; // Style groesse bei sehr kleinen bildschirmen
  const gridSm = 4; // style groesse bei kleinen bildschirmen
  useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  return <Root>
    <h2><Trans>BEV - Kontrollpunkt für Smartphones</Trans></h2>
      <Grid container alignItems="stretch" justifyContent="space-around" spacing={8}>
      <Grid item sm={gridXs} md={gridSm}>
        <MyPaper>
            <BigDoneIcon/>
            <h4><Trans>1. QR Code scannen</Trans></h4>
            <p><Trans>Scannen Sie den QR-Code auf der Metallplatte des Kontrollpunktes und folgen Sie dem angezeigten Link.</Trans></p>
        </MyPaper>
      </Grid>
      <Grid item sm={gridXs} md={gridSm}>
        <MyPaper>
            <BigFixedIcon />
            <h4><Trans>2. GPS aktivieren</Trans></h4>
            <p>
              <Trans>Auf Ihrem Smartphone öffnet sich nun ein Webbrowser mit einer Karte. Stellen Sie sicher, dass auf Ihrem Smartphone die Positionierung mit GPS aktiviert ist.</Trans>
            </p>
        </MyPaper>
      </Grid>
      <Grid item sm={gridXs} md={gridSm}>
        <MyPaper>
            <BigRoomIcon />
            <h4><Trans>3. Genauigkeit ablesen</Trans></h4>
            <p>
              <Trans>Halten Sie Ihr Smartphone über die Mitte der Metallplatte und erfahren Sie in der Karte wie genau das Smartphone Ihre Position anzeigt.</Trans>
            </p>
        </MyPaper>
      </Grid>
    </Grid>
  </Root>
}

export default Descrition;
