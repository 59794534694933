import React, { useEffect } from "react";
import { Card, CardHeader, CardContent, Typography } from "@mui/material";
import { Room as RoomIcon, GpsFixed as GpsFixedIcon } from "@mui/icons-material";
import { Trans, useTranslation } from "react-i18next";
import { styled } from "@mui/material";

const MyCard = styled((p) => <Card elevation={4} {...p} />)({});

const MyCardHeader = styled((p) => <CardHeader {...p} />)({
  "& .MuiCardHeader-title": {
    fontWeight: "bold"
  }
});

const ImgWrapper = styled("div")`
  width: 100%;
  height: 300px;
`;

const Image = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const iconStyling = ({ theme }) => ({
  margin: theme.spacing(1),
  fontWeight: "bold"
});

const HA1 = styled("h1")(({ theme }) => ({
  padding: theme.spacing(2)
}));

const BigGpsFixedIcon = styled((p) => <GpsFixedIcon {...p} />)(iconStyling);
const BigRoomIcon = styled((p) => <RoomIcon {...p} />)(iconStyling);

const CardWrapper = styled("div")(({ theme }) => ({
  
  [theme.breakpoints.down("sm")]: {
    padding: "0 15px",
    display: "grid",
    gridTemplateColumns: "1fr",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },

  [theme.breakpoints.up("sm")]: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
    columnGap: theme.spacing(4),
    rowGap: theme.spacing(4),
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}));

function Accuracy() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <div>
      <HA1>
        <Trans>Weitere Informationen</Trans>
      </HA1>
      <CardWrapper>
        <MyCard>
          <MyCardHeader avatar={<BigGpsFixedIcon />} title={t("Was ist GNSS?")} />

          <CardContent>
            <Typography component="p">
              <Trans>
                Die zur Positionsbestimmung verfügbaren Satelliten werden unter dem Begriff GNSS (Global
                Navigation Satellite System) zusammengefasst. Dazu gehören die Systeme GPS (USA), GLONASS
                (Russland), Beidou (China) und Galileo (Europa). Moderne GNSS-Empfänger können bereits mehrere
                dieser Systeme gleichzeitig benutzen.
              </Trans>
            </Typography>
          </CardContent>
        </MyCard>
        <MyCard>
          <MyCardHeader avatar={<BigGpsFixedIcon />} title={t("GNSS Messprinzip")} />

          <ImgWrapper>
            <Image src={t("/800x800pix3.jpg")} alt={t("GNSS Messprinzip")} />
          </ImgWrapper>

          <CardContent>
            <Typography component="p">
              <Trans>
                Das GNSS-Modul in Ihrem Smartphone empfängt Signale (Codes) von mehreren Satelliten. Aus
                diesen Signalen können die einzelnen Entfernungen zwischen dem jeweiligen Satellit und dem
                Empfänger abgeleitet werden. Sind die Distanzen zu mindestens 4 Satelliten bekannt, kann die
                räumliche Position des Empfängers berechnet werden. Die Genauigkeit der Position Ihres
                Smartphones ist in der Regel besser als 10 Meter.
              </Trans>
            </Typography>
            <Typography component="p">
              <Trans>
                GNSS wird auch für genaue Vermessungsarbeiten im cm-Bereich eingesetzt, dazu sind aber
                leistungsfähigere GNSS-Empfänger sowie spezielle Mess- und Auswertemethoden erforderlich.
              </Trans>
            </Typography>
          </CardContent>
        </MyCard>

        <MyCard>
          <MyCardHeader avatar={<BigRoomIcon />} title={t("Koordinatensystem WGS84")} />

          <ImgWrapper>
            <Image src={t("/800x800pix2.jpg")} alt={t("Koordinatensystem WGS84")} />
          </ImgWrapper>

          <CardContent>
            <Typography component="p">
              <Trans>
                Der GNSS-Empfänger zeigt seine Position in Form von Koordinaten (geografische Länge und
                Breite) an. Diese beziehen sich in der Regel auf das weltweite Koordinatensystem WGS84 (World
                Geodetic System 1984). Der Ursprung dieses Systems liegt im Schwerpunkt der Erde.
              </Trans>
            </Typography>
            <Typography component="p">
              <Trans>
                Die geografische Länge L zählt vom Nullmeridian in Greenwich/England nach Osten positiv und
                nach Westen negativ (-180° = L = 180°). Die geografische Breite B zählt vom Äquator nach
                Norden positiv und nach Süden negativ (-90° = B = 90°). Aufgrund seiner geografischen Lage
                sind für Österreich (A) beide Koordinatenwerte positiv.
              </Trans>
            </Typography>
          </CardContent>
        </MyCard>

        <MyCard>
          <MyCardHeader avatar={<BigRoomIcon />} title={t("Abbildung UTM")} />

          <ImgWrapper>
            <Image src={t("/800x800pix1.jpg")} alt={t("Abbildung UTM")} />
          </ImgWrapper>

          <CardContent>
            <Typography component="p">
              <Trans>
                Aus den vom GNSS-Empfänger gemessenen Koordinaten (geografische Länge und Breite) werden über
                eine Kartenprojektion Koordinaten auf einem die Erde berührenden Zylinder berechnet. Auf
                dessen ausgerolltem Mantel, der Karte, werden die Hochwerte in Nord-Süd-Richtung ausgehend vom
                Äquator und die Rechtswerte in Ost-West-Richtung ausgehend vom Berührmeridian gezählt.
              </Trans>
            </Typography>
            <Typography component="p">
              <Trans>
                Um Verzerrungen möglichst gering zu halten, werden nur 6° breite Meridianstreifen abgebildet.
                International werden die insgesamt 60 Streifen eindeutig festgelegt und als Zonen bezeichnet.
                Österreich wird zur Gänze von den beiden Zonen 32 und 33 mit den Mittelmeridianen 9° und 15°
                östlich von Greenwich abgedeckt.
              </Trans>
            </Typography>
          </CardContent>
        </MyCard>

        <MyCard>
          <MyCardHeader avatar={<BigRoomIcon />} title={t("Höhe")} />

          <ImgWrapper>
            <Image src={t("/800x800pix7.jpg")} alt={t("Höhe")} />
          </ImgWrapper>

          <CardContent>
            <Typography component="p">
              <Trans>
                Im Koordinatensystem WGS84 wird die Höhe als der vertikale Abstand zur Oberfläche des
                Erdellipsoids WGS84 (Näherungsfläche der Erdfigur) angegeben (ellipsoidische Höhe Hell).
              </Trans>
            </Typography>
            <Typography component="p">
              <Trans>
                Eine Höhe kann auch über einen Höhenbezugspegel am Meer definiert werden. Diese Meereshöhen
                (H) beziehen sich vereinfacht betrachtet auf eine im Meeresniveau befindlichen Niveaufläche
                mit der Bezeichnung Geoid.
              </Trans>
            </Typography>
            <Typography component="p">
              <Trans>
                Die Meereshöhen werden aus Höhendifferenzen des Präzisionsnivellements (=hochgenaue
                Höhenmessung) und der an den Höhenfestpunkten gemessenen Schwerebeschleunigung abgeleitet.
              </Trans>
            </Typography>
            <Typography component="p">
              <Trans>
                Die Schwerebeschleunigung wird mit speziellen Messgeräten, den Gravimetern, gemessen. Sie
                variiert auf der Erde aufgrund der Ortsabhängigkeit (Lage und Höhe) und der Dichte des
                Gesteins (Erdkrustenaufbau) zwischen 9,78 m/s2 am Äquator und 9,83 m/s2 an den Polen.
              </Trans>
            </Typography>

            <Typography component="p">
              <Trans>
                Der Unterschied der Meereshöhe zur ellipsoidischen Höhe wird Geoidundulation N genannt.
              </Trans>
            </Typography>
            <Typography component="p">
              <Trans>Dieser Wert liegt in Österreich bei ca. 40-50 Meter.</Trans>
            </Typography>
          </CardContent>
        </MyCard>

        <MyCard>
          <MyCardHeader avatar={<BigRoomIcon />} title={t("Assisted GPS (A-GPS)")} />

          <ImgWrapper>
            <Image src={t("/800x800pix6.jpg")} alt={t("Assisted GPS (A-GPS)")} />
          </ImgWrapper>

          <CardContent>
            <Typography component="p">
              <Trans>
                Ein Smartphone verwendet zur Positionsbestimmung wenn möglich nicht nur Satellitendaten.
                Assisted Global Positioning System (A-GPS) nutzt zusätzliche Informationen wie die aktuelle
                Funkzelle des Smartphones oder einen aktuellen WLAN-Zugangspunkt. Auch die Bahndaten der
                Satelliten werden am Gerät gespeichert. Dadurch ist es möglich, die Position viel schneller
                und frei von Unterbrechungen zu erhalten, auch wenn weniger als 4 Satelliten empfangen werden.
              </Trans>
            </Typography>
          </CardContent>
        </MyCard>

        <MyCard>
          <MyCardHeader avatar={<BigRoomIcon />} title={t("Wie genau ist mein Smartphone?")} />

          <ImgWrapper>
            <Image src={t("/800x800pix5.jpg")} alt={t("Wie genau ist mein Smartphone?")} />
          </ImgWrapper>

          <CardContent>
            <ol>
              <li>
                <Trans>
                  Scannen Sie den QR-Code auf der Metallplatte des Kontrollpunktes und folgen Sie dem
                  angezeigten Link.
                </Trans>
              </li>
              <li>
                <Trans>
                  Auf Ihrem Smartphone öffnet sich nun ein Webbrowser mit einer Karte. Stellen Sie sicher,
                  dass auf Ihrem Smartphone die Positionierung mit GPS aktiviert ist.
                </Trans>
              </li>
              <li>
                <Trans>
                  Halten Sie Ihr Smartphone über die Mitte der Metallplatte und erfahren Sie in der Karte wie
                  genau das Smartphone Ihre Position anzeigt.
                </Trans>
              </li>
            </ol>
          </CardContent>
        </MyCard>

        <MyCard>
          <MyCardHeader avatar={<BigRoomIcon />} title={t("Koordinatenvergleich")} />

          <ImgWrapper>
            <Image src={t("/800x800pix8.jpg")} alt={t("Koordinatenvergleich")} />
          </ImgWrapper>

          <CardContent>
            <Typography component="p">
              <Trans>
                Wenn Sie ein mobiles Navigationsgerät oder eine entsprechende App auf Ihrem Smartphone nutzen,
                können Sie Ihre gemessenen Koordinatenwerte auch direkt mit den Koordinaten auf der
                Metallplatte des Kontrollpunktes vergleichen.
              </Trans>
            </Typography>
            <Typography component="p">
              <Trans>
                Achten Sie dabei darauf, dass Ihre Einstellungen am Gerät den Angaben auf der Metallplatte des
                Kontrollpuntkes entsprechen (zB WGS84, UTM).
              </Trans>
            </Typography>
          </CardContent>
        </MyCard>
      </CardWrapper>
    </div>
  );
}

export default Accuracy;
