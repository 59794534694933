import React from 'react';
import {Link} from 'react-router-dom'
import { Trans, useTranslation } from "react-i18next";
import { styled } from '@mui/material';

const Banner = styled('div')(({theme}) => ({
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    boxShadow: '0px 2px 8px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
}))

const MyLink = styled(p => <Link {...p} />)(({theme}) => ({
    color: theme.palette.primary.main
}))

function AccuracyBanner () {
    useTranslation();
     return <Banner>
        <h3><Trans>Wie funktioniert eigentlich die Positionsbestimmung?</Trans></h3>
        <p><Trans>Weitere Informationen finden Sie</Trans><MyLink to="/accuracy"><Trans>hier</Trans></MyLink>.</p>
    </Banner>

}

export default AccuracyBanner