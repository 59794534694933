import React from "react";
import { CardActions } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const MyCardActions = styled((props) => <CardActions {...props} />)`
    background-color: #fff;
    color: #333; 
`
const primaryColored = ({theme}) => ({
    color: theme.palette.primary.main
})

const ThemaHeader = styled('h3')(primaryColored);
const ThemeLink = styled('a')(primaryColored);

const Footer = () => {
  const { t } = useTranslation();
  return (
    <MyCardActions>
      <div>
        <ThemaHeader>{t("impressum_title")}</ThemaHeader>
        <address style={{ fontStyle: "normal", color: '#333' }}>
          <strong>{t("impressum_organisation")}</strong>
          <br />
          {t("impressum_street")}
          <br />
          {t("impressum_post_code")}
          <br />
          <br />
          Tel. +43 1 21110-0
          <br />
          <ThemeLink href="http://www.bev.gv.at" >www.bev.gv.at</ThemeLink>
          <br />
          <ThemeLink href="mailto:info@bev.gv.at">info@bev.gv.at</ThemeLink>
          <br />
          <br />
          {t("impressum_vat_number")}
          <br />
          <br />
          {t("impressum_bank")}
          <br />
          {t("impressum_iban")}
          <br />
          {t("impressum_bic")}
          <br />
          <br />
          {t("impressum_regulatory_body")}
          <br />
        </address>
      </div>
    </MyCardActions>
  );
};

export default Footer;
