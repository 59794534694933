export const BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';
export const API_URL = BASE_URL + (process.env.REACT_APP_API_URI ? process.env.REACT_APP_API_URI: '/api');

const Number = process.env.REACT_APP_VERSION_NUMBER ? process.env.REACT_APP_VERSION_NUMBER : '0.0.0';
const Message = process.env.REACT_APP_VERSION_MESSAGE ? process.env.REACT_APP_VERSION_MESSAGE : process.env.NODE_ENV;
export const VERSION_NAME = `${Number} - ${Message}`;
export const SENTRY_URL = process.env.REACT_APP_SENTRY_URL ? process.env.REACT_APP_SENTRY_URL : null;
export const SENTRY_RELEASE = process.env.REACT_APP_RELEASE ? process.env.REACT_APP_RELEASE : '0000000000';



/* Helper Functions */
export const roundDist = dist => {
    var output;
    if (dist>1000) {
        output = (Math.round(dist / 1000 * 100) / 100) +' km';
    } else {
        output = (Math.round(dist * 100) / 100) +' m';
    }
    return output;
}


export const getDistance = (origin, destination) => {
    // return distance in meters
    var lon1 = toRadian(origin[1]),
        lat1 = toRadian(origin[0]),
        lon2 = toRadian(destination[1]),
        lat2 = toRadian(destination[0]);

    var deltaLat = lat2 - lat1;
    var deltaLon = lon2 - lon1;

    var a = Math.pow(Math.sin(deltaLat/2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon/2), 2);
    var c = 2 * Math.asin(Math.sqrt(a));
    var EARTH_RADIUS = 6371;
    var dist = c * EARTH_RADIUS * 1000;
    return dist
}

export const toRadian = degree => {
    return degree*Math.PI/180;
}

export const errorCall = err => {
    console.error(err);
}

export const deztodms = koord => {
    var d = parseInt(koord, 10);
    var md = (koord-d)*60;
    var m = parseInt(md, 10);
    var s = parseFloat( (md-m) * 60 ).toFixed(2);
    return d+"° "+m+"' "+s+"''";
}


/* Request Options */

export const watchPositionOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };