import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FormControlLabel, Switch, AppBar, Tabs, Tab, Card, LinearProgress, styled, Divider } from "@mui/material";
import { API_URL, roundDist, getDistance, watchPositionOptions, errorCall } from "components/constants";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Coordinates, PhotoList } from "..";
import PointMap from "../pointMap";
import { isMobile } from "react-device-detect";

const Wrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(2)
}));

/**
 * Liefert die Kontrollpunkt seite als element zurück.
 */
function Pointdata() {
  const { kontrollid } = useParams();
  const { t } = useTranslation();

  const [bounds, setBounds] = useState([
    [46.35877, 8.782379],
    [49.037872, 17.189532]
  ]); //Bounds welche vernwedet werden um den Zoomfaktor der Karte darzusellen
  const [isPositioning, setIsPositioning] = useState(false);
  const [distance, setDistance] = useState(null); // Distanz zwischen Kontrollpunkt und Live standort
  const [markaPosition, setMarkaPosition] = useState(null); // Position des Kontrollpunktes
  const [pos, setPos] = useState(false); // Position Object zur berechnung vom Livestandort
  const [posid, setPosid] = useState(false);
  const [value, setValue] = useState(0); // Variable zur bestimmung welcher Tab angezeigt werden soll

  const { isLoading, isError, error, data } = useQuery(["kontrollpunkte", kontrollid], async () => {
    //TODO const url = `https://kontrollpunkt.bev.gv.at/api/punkt/${kontrollid}/`;
    const url = `${API_URL}/punkt/${kontrollid}/`;
    const res = await axios.get(url);
    const { phi, lam } = res.data;
    setMarkaPosition([phi, lam]);
    return res.data;
  });

  useEffect(() => {
    if (isMobile) {
      // TODO add on next version 
      // history.push(kontrollid + "/mobile/");
    }
  }, []);

  useEffect(() => {
    if (isPositioning) {
      startWatching();
    } else {
      stopWatching();
    }
  }, [isPositioning]);

  /**
   * Wenn der Positions Watcher läuft wird dieser mit stopWatching beendet.
   */
  const stopWatching = () => {
    navigator.geolocation.clearWatch(posid);
    setPos(false);
    setPosid(false);
    setDistance(null);
    setBounds([
      [46.35877, 8.782379],
      [49.037872, 17.189532]
    ]);
  };

  /**
   * Funktion welche LivePostions veränderungen wahrnimmt und dann dem entsprechend die variablen zur anzeige auf der Karte setzt !
   */
  const startWatching = () => {
    setDistance("-");
    const cPosid = navigator.geolocation.watchPosition(
      (position) => {
        const { coords } = position;
        const { latitude, longitude } = coords;
        const cBounds = [[latitude, longitude], markaPosition];
        const distance = getDistance(cBounds[0], cBounds[1]);

        setPos(coords);
        setDistance(distance);
        setBounds(cBounds);
      },
      errorCall,
      watchPositionOptions
    );
    setPosid(cPosid);
  };

  /**
   * Funktion welche beim toggle den Postions Watcher startet oder schließt.
   * @param {*} event
   */
  const toggleIsPositioning = (event) => {
    setIsPositioning(event.target.checked);
  };

  // Funktion um Tab zu wechseln.
  const handleTabChange = (event, value) => setValue(value);

  if (isLoading) return <LinearProgress color="primary" />;
  if (isError)
    return (
      <AppBar position="static">
        <h3>ES IST EST WAS SCHIEF GEGENAGEN: {error.message}</h3>
      </AppBar>
    );

  return (
    <div>
      <Wrapper>
        <h2>
          <Trans>BEV - Kontrollpunkt für Smartphones</Trans> in {data.fullname}
        </h2>
        <h3>
          <Trans>geodätischer Referenzpunkt</Trans>
        </h3>
        <FormControlLabel
          control={
            <Switch color="primary" checked={isPositioning} onChange={toggleIsPositioning} value="checkedA" />
          }
          label={isPositioning ? t("Positionsbestimmung aktiviert") : t("Positionsbestimmung deaktiviert")}
        />
        {isPositioning && (
          <div>
            <h3>
              {t(!pos ? "Position wird geladen" : "Differenz zum Kontrollpunkt")}:
              {pos && <span> {roundDist(distance)}</span>}
            </h3>
            {!pos && <LinearProgress variant="query" color="primary" />}
          </div>
        )}
      </Wrapper>
      <div>
        <div style={{ flexGrow: 1, width: "100%" }}>
          <AppBar position="static" color="default">
            <Tabs value={value} onChange={handleTabChange} variant="scrollable" indicatorColor="primary">
              <Tab label={t("Kartenansicht")} />
              <Tab label={t("Koordinaten")} />
              {data.pictures.length > 0 && <Tab label={t("Bilder")} />}
            </Tabs>
          </AppBar>
        </div>

        {value === 0 && <PointMap list={[data]} position={pos} bounds={bounds} distance={roundDist(distance)} />}
        {value === 1 && <Coordinates data={data} />}
        {value === 2 && <PhotoList images={data.pictures} />}
      </div>
      <Divider />
    </div>
  );
}

export default Pointdata;
