import React, { useState } from "react";
import { Box } from "@mui/material";
import ImageListItem, { imageListItemClasses } from "@mui/material/ImageListItem";
import PhotoFullscreen from "../PhotoFullscreen";

const PhotoList = ({ images }) => {
  const [activeSrc, setActiveSrc] = useState(null);
  const handleClose = (e) => {
    setActiveSrc(null);
  };

  const handleNext = (e) => {
    const i = images.findIndex((i) => i.gross === activeSrc.src);
    changeImageToIndex(i + 1);
  };

  const handlePrev = (e) => {
    const i = images.findIndex((i) => i.gross === activeSrc.src);
    changeImageToIndex(i - 1);
  };

  const changeImageToIndex = (index) => {
    if (index < 0)
      return setActiveSrc({
        src: images[images.length - 1].gross,
        alt: images[images.length - 1].description
      });
    if (index >= images.length) return setActiveSrc({ src: images[0].gross, alt: images[0].description });
    return setActiveSrc({ src: images[index].gross, alt: images[index].description });
  };

  return (
    <div>
      <Box sx={{display: "flex", justifyContent: 'center', padding: 1}}>
      <Box
    sx={{
      display: "grid",
      gridTemplateColumns: {
        xs: "repeat(2, 1fr)",
        sm: "repeat(4, 1fr)",
        md: "repeat(5, 1fr)",
        lg: "repeat(6, 1fr)",
        xl: "repeat(8, 1fr)",
      },
      gap: 2,
      [`& .${imageListItemClasses.root}`]: {
        display: "flex",
        flexDirection: "column"
      }
    }}
  >
          {images.map((item, i) => (
            <ImageListItem
              key={item.id}
              onClick={() => setActiveSrc({ src: item.gross, alt: item.description })}
              sx={{border: 'solid 1px transparent', cursor: 'pointer', border: 'solid 1px #333', padding: t => t.spacing(1), '&:hover' : {border: 'solid 1px red'}}}
            >
              <img
                src={`${item.klein}?w=248&fit=crop&auto=format`}
                srcSet={`${item.klein}?w=248&fit=crop&auto=format&dpr=2 2x`} 
                alt={item.description}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </Box>
      </Box>
      <PhotoFullscreen
        open={!!activeSrc}
        data={activeSrc}
        handleClose={handleClose}
        next={handleNext}
        prev={handlePrev}
      />
    </div>
  );
};

export default PhotoList;


                /*

                          src={`http://10.70.2.39:8081${item.klein}?w=248&fit=crop&auto=format`}
                srcSet={`http://10.70.2.39:8081${item.klein}?w=248&fit=crop&auto=format&dpr=2 2x`}
          
          +
              src={`https://kontrollpunkt.bev.gv.at/${item.klein}?w=248&fit=crop&auto=format`}
              srcSet={`https://kontrollpunkt.bev.gv.at/${item.klein}?w=248&fit=crop&auto=format&dpr=2 2x`}*/