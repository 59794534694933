/*jshint -W065 */
import React from "react";
import { Grid, Card, CardHeader, CardContent, styled } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";
import { Trans, useTranslation } from "react-i18next";
import { deztodms } from "components/constants";

const MyLink = styled('a')(({ theme }) => ({
  padding: "0.5em",
  border: "solid 1px #333",
  borderRadius: "0.25em",
  color: "#333",
  textDecoration: "none",
  "&:hover": {
    color: "#000",
    textDecoration: "none",
    backgroundColor: "#eee"
  }
}));

const MyGrid = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: "0 15px",
    display: "grid",
    gridTemplateColumns: "1fr",
    columnGap: 15,
    rowGap: 15
  },

  [theme.breakpoints.up("sm")]: {
    padding: "0 30px",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(340px, 1fr))",
    columnGap: 30,
    rowGap: 30
  }
}));

function Coordinates({ data }) {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" spacing={2} style={{ marginTop: "20px", marginBottom: "24px" }}>
      {!!data.punktkarte && (
        <Grid item xs={12}>
          <MyLink href={data.punktkarte} target="blank">
            <Trans>Punktkarte</Trans>
          </MyLink>
        </Grid>
      )}
      <Grid item xs={12}>
        <MyGrid>
          <Card elevation={2}>
            <CardHeader avatar={<RoomIcon style={{ fontSize: 36 }} />} title={t("UTM Koordinaten")} />

            <CardContent>
              <center>
                <p>
                  <Trans>Nord</Trans> = {data.utm_hw} m
                </p>
                <p>
                  <Trans>Ost</Trans> = {data.utm_rw} m
                </p>
                <p>{data.utm_zone} </p>
              </center>
            </CardContent>
          </Card>

          <Card elevation={2}>
            <CardHeader avatar={<RoomIcon style={{ fontSize: 36 }} />} title={t("WGS84 Koordinaten")} />

            <CardContent>
              <center>
                <p>
                  <Trans>Länge</Trans>: {deztodms(data.lam)} <Trans>Ost</Trans>
                </p>
                <p>
                  <Trans>Breite</Trans>: {deztodms(data.phi)} <Trans>Nord</Trans>
                </p>
              </center>
            </CardContent>
          </Card>

          <Card elevation={2}>
            <CardHeader avatar={<RoomIcon style={{ fontSize: 36 }} />} title={t("Höhe und Schwere")} />

            <CardContent>
              <center>
                <p>
                  <Trans>Ellipsoidische Höhe</Trans> = {data.h_ell} m
                </p>
                <p>
                  <Trans>Höhe über Adria</Trans> = {data.h_gebr} m
                </p>
                <p>
                  <Trans>Schwerebeschleunigung</Trans> = {data.g} m/s<sup>2</sup>
                </p>
              </center>
            </CardContent>
          </Card>
        </MyGrid>
      </Grid>
    </Grid>
  );
}

export default Coordinates;
