import React from "react";
import { Route, Switch } from "react-router-dom";
import { Card, CardContent, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Header,
  Footer,
  Description,
  Accuracy,
  AccuracyBanner,
  PointsList,
  PointData,
  PointDataMobile
} from "./components";

const Root = styled((props) => <Container {...props} />)`
  flex-grow: 0;
`;
const MyCard = styled((props) => <Card {...props} />)`
  position: relative;
  margin-top: 0;
  padding-top: 0;
`;

const MyCardContent = styled((p) => <CardContent {...p} />)`
  padding: 0;
`;

function App() {
  return (
    <Root maxWidth="lg">
      <MyCard>
        <Header />
        <MyCardContent>
          <main style={{paddingBottom: 16}}>
            <Route exact path="/">
              <Description />
              <PointsList />
              <AccuracyBanner />
            </Route>
            <Switch>
              <Route exact path="/accuracy/" component={Accuracy} />
              <Route exact path="/:kontrollid/" component={PointData} />
              <Route exact path="/:kontrollid/mobile/" component={PointDataMobile} />
            </Switch>
          </main>
        </MyCardContent>

        <Footer />
      </MyCard>
    </Root>
  );
}

export default App;
