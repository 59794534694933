import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FormControl, InputLabel, Select, MenuItem, CardMedia } from "@mui/material";
import { styled } from "@mui/material/styles";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";

const MediaWrapper = styled("div")({
  position: "relative"
});

const MyCardMedia = styled((props) => <CardMedia {...props} />)({
  paddingTop: "31%"
});

const MyFormControl = styled((props) => <FormControl {...props} />)(({theme}) => ({
  minWidth: 75,
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1)
}));

const MySelect = styled((props) => <Select {...props} />)({
    '& .MuiSelect-select':{
        paddingTop: 7,
        paddingBottom: 5,
        backgroundColor: "#fafafa",
        boxShadow: "0px 0px 30px 10px #fafafa"
    }
});

const Header = () => {
  const { i18n } = useTranslation();
  const [lng, setLng] = useState(i18n.language.toLowerCase().includes("de") ? "de" : "en");

  const changeLanguage = (event) => {
    setLng(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <MediaWrapper>
      <Link to="/">
        <MyCardMedia image="/CIM_09777_bearb_MIT_Logo_weiss.jpg" title="BEV Kontrollpunkt" />
      </Link>
      <MyFormControl variant="outlined">
        <InputLabel id="select-language-label" style={{ display: "inline-flex", alignItems: "center" }}>
          <LanguageIcon style={{ fontSize: "1rem" }} /> {lng.toUpperCase()}
        </InputLabel>
        <MySelect
          labelId="select-language-label"
          id="select-language"
          onChange={changeLanguage}
          value={lng}
          label="language"
        >
          <MenuItem value={"en"}>EN - Englisch</MenuItem>
          <MenuItem value={"de"}>DE - Deutsch</MenuItem>
        </MySelect>
      </MyFormControl>
    </MediaWrapper>
  );
};

export default Header;
