import React from "react";
import { styled, Backdrop, IconButton, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const PrevWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: theme.zIndex.drawer + 2,
  bottom: theme.spacing(4),
  left: theme.spacing(4)
}));

const NextWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: theme.zIndex.drawer + 2,
  bottom: theme.spacing(4),
  right: theme.spacing(4)
}));

const StyledIconButton = styled((p) => <IconButton {...p} />)(({ theme }) => ({
  backgroundColor: "white",
  fontSize: "2em",

  "&:hover": {
    backgroundColor: "#aaa"
  }
}));

const PhotoFullscreen = ({ data, open, handleClose, next, prev }) => {
  const stopPropagation = (f) => (e) => {
      e.stopPropagation();
      f();
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <PrevWrapper>
        <StyledIconButton color="primary" onClick={stopPropagation(prev)} size="large">
          <ChevronLeft />
        </StyledIconButton>
      </PrevWrapper>
      {data && <img alt={data.alt} src={data.src} />}
      {data && data.description && <Typography variant="p"sx={{display: 'felx', position: 'absolute', top: t=> t.spacing(2), left: t => t.spacing(2)}}>{data.description}</Typography>}
      <NextWrapper>
        <StyledIconButton color="primary" onClick={stopPropagation(next)} size="large">
          <ChevronRight />
        </StyledIconButton>
      </NextWrapper>
    </Backdrop>
  );
};

export default PhotoFullscreen;

/**
      {data && <img alt={data.alt} src={"http://10.70.2.39:8081"+data.src} />}
 * 
 */