import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AppBar, Tabs, Tab, LinearProgress, styled } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";
import { useTranslation, Trans } from "react-i18next";
import { API_URL } from "components/constants";
import { useQuery } from "react-query";
import axios from "axios";
import { PointMap } from "components";

const TabContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

const MyGrid = styled("div")(({ theme }) => ({
  "@media (max-width: 200px)": {
    display: "grid",
    gridTemplateColumns: "1fr",
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1)
  },

  "@media (min-width: 201px)": {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2)
  }
}));

const ImageWrapper = styled((p) => <Link {...p} />)(({ theme }) => ({
  width: "100%",
  height: 150,
  color: theme.palette.primary.main,
  position: "relative",
  cursor: "pointer",
  "&:hover": {
    color: "#333"
  }
}));

const Image = styled('img')(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover"
}));
const Marker = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}));
const Icon = styled((p) => <RoomIcon {...p} />)`
  font-size: 6rem;
`;
const Label = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "30%",
  backgroundColor: "hsl(0,0%,0%,0.6)",
  padding: theme.spacing(1),
  fontWeight: "bold",
  color: "white",
  boxSizing: "border-box"
}));

const PointsList = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const { isLoading, isError, error, data } = useQuery(["kontrollpunkte"], async () => {
    //const url = `https://kontrollpunkt.bev.gv.at/api/punkt/`;
    const url = `${API_URL}/punkt/`;
    const res = await axios.get(url);
    return res.data;
  });
  const handleChange = (event, value) => setValue(value);

  const getPicture = (marker) => {
    if (hasPicture(marker)) {
      return marker.pictures[0].klein;
      //return "http://10.70.2.39:8081" + marker.pictures[0].klein;
    }
    return "";
  };

  const hasPicture = (marker) => {
    if (!marker) return false;
    if (!marker.pictures) return false;
    if (!marker.pictures[0]) return false;
    if (!marker.pictures[0].klein) return false;
    return true;
  };

  if (isLoading) return <LinearProgress color="primary" />;
  if (isError)
    return (
      <AppBar position="static">
        <h3>ES IST EST WAS SCHIEF GEGENAGEN: {error.message}</h3>
      </AppBar>
    );

  return (
    <>
      <AppBar position="static" color="default">
        <Tabs value={value} onChange={handleChange} indicatorColor="primary">
          <Tab label={t("Kartenansicht")} />
          <Tab label={t("Listenansicht")} />
        </Tabs>
      </AppBar>

      {value === 0 && <PointMap list={data} />}
      {value === 1 && (
        <TabContainer>
          <h2>
            <Trans>Kontrollpunkt auswählen</Trans>
          </h2>
          <MyGrid>
            {data
              .sort((a, b) => a.fullname.localeCompare(b.fullname))
              .map((marker, i) => (
                <ImageWrapper key={i} to={"/" + marker.name + "/"}>
                  {/*hasPicture(marker) && <Image src={getPicture(marker)} alt={marker.name} />*/}
                  <Marker>
                    <Icon />
                  </Marker>
                  <Label>{marker.fullname}</Label>
                </ImageWrapper>
              ))}
          </MyGrid>
        </TabContainer>
      )}
    </>
  );
};

export default PointsList;
