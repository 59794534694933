import React, { useEffect, useRef, useState } from "react";
import "ol/ol.css";
import { Map, View, Feature } from "ol";
import { fromLonLat } from "ol/proj";
import { ScaleLine, ZoomSlider, Zoom } from "ol/control";
import { DEVICE_PIXEL_RATIO } from "ol/has";
import { Select } from "ol/interaction";
import Overlay from "ol/Overlay";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import Point from "ol/geom/Point";
import XYZ from "ol/source/XYZ";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { styleFunction, styleFunctionHover } from "./olStylings";
import { json } from "./austiranGeoJson";
import { pointerMove } from "ol/events/condition";
import { Link } from "react-router-dom";
import { styled, Typography } from "@mui/material";

const MapPopup = styled("div")(({ theme }) => ({
  position: "absolute",
  backgroundColor: "white",
  boxShadow: "0 1px 4px rgba(0,0,0,0.2)",
  padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
  borderRadius: 10,
  border: "1px solid #cccccc",
  bottom: 12,
  left: -50,
  minWidth: 100,
  whiteSpace: "nowrap",

  "&:after": {
    top: "100%",
    border: "solid transparent",
    content: '" "',
    height: 0,
    width: 0,
    position: "absolute",
    pointerEvents: "none",
    borderTopColor: "white",
    borderWidth: 10,
    left: 48,
    marginLeft: -10
  },

  "&:bevor": {
    top: "100%",
    border: "solid transparent",
    content: '" "',
    height: 0,
    width: 0,
    position: "absolute",
    pointerEvents: "none",
    borderTopColor: "#cccccc",
    borderWidth: 11,
    left: 48,
    marginLeft: -11
  }
}));

export const PointMap = ({ list, height, position, bounds, distance }) => {
  const mapContainer = useRef(null);
  const [activeLink, setActiveLink] = useState(null);
  const [activeName, setActiveName] = useState(null);
  const [map, setMap] = useState(null);
  const gpsVectorLayer = useRef(null);
  const listVectorLayer = useRef(null);
  const overlay = useRef(null);

  // OnInit set init map an layers,
  useEffect(() => {
    const view = new View({
      center: fromLonLat([15, 47]),
      zoom: 7,
      maxZoom: 21
    });
    const tileLayerOMS = new TileLayer({
      source: new OSM(),
      title: "OpenStreetMap",
      showInLegend: true,
      visible: true,
      zIndex: 20
    });

    const hiDPI = DEVICE_PIXEL_RATIO > 1;
    const layer = hiDPI ? "bmaphidpi" : "geolandbasemap";
    const tilePixelRatio = hiDPI ? 2 : 1;
    const tileLayerBaseMap = new TileLayer({
      source: new XYZ({
        url: `https://maps{1-4}.wien.gv.at/basemap/${layer}/normal/google3857/{z}/{y}/{x}.png`,
        maxZoom: 20,
        tilePixelRatio,
        crossOrigin: "anonymous"
      }),
      title: "BaseMap",
      zIndex: 25,
      visible: true
    });

    const tileLayerOrthoPhoto = new TileLayer({
      source: new XYZ({
        url: `https://maps{1-4}.wien.gv.at/basemap/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jepg`,
        maxZoom: 20,
        tilePixelRatio,
        crossOrigin: "anonymous"
      }),
      title: "Orthophoto",
      zIndex: 30,
      visible: false
    });


    gpsVectorLayer.current = new VectorLayer({
      style: styleFunctionHover,
      zIndex: 47,
      name: "gps"
    });

    listVectorLayer.current = new VectorLayer({
      style: styleFunction,
      zIndex: 49,
      name: "points"
    });

    const vectorLayerAustria = new VectorLayer({
      visible: false,
      zIndex: 1,
      source: new VectorSource({
        features: new GeoJSON({ featureProjection: "EPSG:3857" }).readFeatures(json)
      })
    });

    const newMap = new Map({
      layers: [
        tileLayerOMS,
        tileLayerBaseMap,
        tileLayerOrthoPhoto,
        listVectorLayer.current,
        vectorLayerAustria,
        gpsVectorLayer.current
      ],
      target: mapContainer.current,
      view: view,
      controls: [new ScaleLine(), new ZoomSlider(), new Zoom()]
    });

    const container = document.getElementById("popup");
    overlay.current = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });
    newMap.addOverlay(overlay.current);

    newMap.on("moveend", () => {
      const center = newMap.getView().getCenter();
      const inAustria = vectorLayerAustria
        .getSource()
        .getFeatures()
        .reduce((a, b) => (a ? true : b.getGeometry().intersectsCoordinate(center)), false);
      const newZoom = newMap.getView().getZoom();
      newMap
        .getLayerGroup()
        .getLayers()
        .forEach((element) => {
          if (element.get("title") === "Orthophoto") {
            element.setVisible(newZoom >= 18 && inAustria);
          }
          if (element.get("title") === "BaseMap") {
            element.setVisible(newZoom < 18 && inAustria);
          }
        });
    });

    setMap(newMap);
  }, []);

  useEffect(() => {
    if(map && list){
      const features = list.map(
        (p) =>
          new Feature({
            geometry: new Point(fromLonLat([p.lam, p.phi])),
            name: p.fullname,
            link: p.name
          })
      );

      listVectorLayer.current.setSource(
        new VectorSource({
          features: features
        })
      );

      if (list.length > 1) {
        map.on("pointermove", function (event) {
          const mouseCoordInMapPixels = [event.originalEvent.offsetX, event.originalEvent.offsetY];
  
          //detect feature at mouse coords
          const hit = map.forEachFeatureAtPixel(mouseCoordInMapPixels, function (feature, layer) {
            return true;
          });
  
          if (hit) {
            mapContainer.current.style.cursor = "pointer";
          } else {
            mapContainer.current.style.cursor = "";
          }
        });
  
        map.on("click", function (event) {
          //detect feature at mouse coords
          const feature = map.forEachFeatureAtPixel(event.pixel, function (feature) {
            return feature;
          });
  
          if (feature) {
            const coordinate = event.coordinate;
            overlay.current.setPosition(coordinate);
            const link = feature.get("link");
            const name = feature.get("name");
            setActiveLink(link);
            setActiveName(name);
          } else {
            overlay.current.setPosition(undefined);
            setActiveLink(null);
            setActiveName(null);
          }
        });
  
        const hoverInteraction = new Select({
          condition: pointerMove,
          style: styleFunctionHover,
          layers: [listVectorLayer.current], //Setting layers to be hovered
          hitTolerance: 2
        });
  
        const clickInteraction = new Select({
          style: styleFunctionHover,
          layers: [listVectorLayer.current], //Setting layers to be hovered
          hitTolerance: 2
        });
  
        map.addInteraction(hoverInteraction);
        map.addInteraction(clickInteraction);
        map.getView().setCenter(fromLonLat([13.32456, 47.59397]));
        map.getView().setZoom(7.5);
      } else {
        const p = list[0];
        map.getView().setCenter(fromLonLat([p.lam, p.phi]));
        map.getView().setZoom(18);
      }
    }

  },[map, list])

  useEffect(() => {
    if (map && position) {
      const feature = new Feature({
        geometry: new Point(fromLonLat([position.longitude, position.latitude])),
        name: distance || ""
      });

      gpsVectorLayer.current.setSource(
        new VectorSource({
          features: [feature]
        })
      );
    }
  }, [position, map]);

  useEffect(() => {
    if (map && bounds) {
      const bound1 = bounds[0][1] > bounds[1][1] ? bounds[1][1] : bounds[0][1];
      const bound2 = bounds[0][0] > bounds[1][0] ? bounds[1][0] : bounds[0][0];
      const bound3 = bounds[0][1] > bounds[1][1] ? bounds[0][1] : bounds[1][1];
      const bound4 = bounds[0][1] > bounds[1][1] ? bounds[0][0] : bounds[1][0];

      const nextExtend = [
        fromLonLat([bound1, bound2]),
        fromLonLat([bound3, bound4])
      ].flat();
      if (nextExtend) map.getView().fit(nextExtend, { padding: [50, 100, 50, 100] });
    }
  }, [map, position, bounds]);

  let localHeight = 600;
  if (height) {
    localHeight = height;
  }
  return (
    <div id="mapContainer" ref={mapContainer} style={{ width: "100%", height: localHeight }}>
      <MapPopup id="popup">
        <Typography>{!!activeLink && <Link to={activeLink}>{activeName}</Link>}</Typography>
      </MapPopup>
    </div>
  );
};

export default PointMap;
