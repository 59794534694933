import { Fill, Stroke, Text } from "ol/style";
import { Icon, Style } from "ol/style";

export const styleFunction = (f) => {
  return [createPointStyle(f.get("name"))];
};

export const styleFunctionHover = (f) => {
    return [createPointStyle(f.get("name"), true)];
  };

const createPointStyle = (text, hover) => {
  return new Style({
    image: new Icon({
      src: "data:image/svg+xml;base64," + window.btoa(createMarkSvg(hover)),
      anchor: [0.5, 1],
      anchorOrigin: "bottom-center",
      scale: 1.5,
      rotation: 0
    }),
    text: text ? labelStyling(text, hover) : null
  });
};

const createStroke = (color, width) => {
  return new Stroke({
    color: color,
    width: width
  });
};

const labelStyling = (label, hover) =>
  new Text({
    font: "14px Calibri,sans-serif",
    fill: new Fill({ color: "#333" }),
    stroke: createStroke("#fff", hover ? 6: 3),
    offsetY: -40, //10
    text: label
  });

const createMarkSvg = (hover) => {
  let mark = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g>`;
  mark += `<path style="fill: ${ hover ? '#333': 'rgba(230, 50, 15, 1)'}" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path>`;
  mark += `</g></svg>`;
  return mark;
};
