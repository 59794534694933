import React from "react";
import ReactDOM from "react-dom";
import "./i18n/index";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./app";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { VERSION_NAME, SENTRY_URL, SENTRY_RELEASE } from "components/constants";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Version from "./components/version";
import { QueryClient, QueryClientProvider } from "react-query";
import reportWebVitals from "reportWebVitals";

Sentry.init({
  dsn: SENTRY_URL,
  release: SENTRY_RELEASE,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
});

const theme = createTheme({
  typography: {
    fontFamily: "Source Sans Pro"
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff"
    },
    background: {
      paper: "#fff",
      default: "rgba(255, 255, 255, 1)"
    },
    primary: {
      light: "rgba(255, 106, 62, 1)",
      main: "rgba(230, 50, 15, 1)",
      dark: "rgba(171, 0, 0, 1)",
      contrastText: "#fff"
    },
    secondary: {
      light: "#fff",
      main: "#ccc",
      dark: "#9b9b9b",
      contrastText: "rgba(0, 0, 0, 1)"
    }
  }
});

const queryClient = new QueryClient();

const app = (
  <Router>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
      <Version version={VERSION_NAME} />
    </ThemeProvider>
  </Router>
);

ReactDOM.render(app, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
