import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  styled,
  LinearProgress,
  AppBar,
  Dialog,
  DialogContent,
  Switch,
  FormControlLabel,
  DialogTitle
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { API_URL, roundDist, getDistance, watchPositionOptions, errorCall } from "components/constants";
import { PointMap } from "..";

const Wrapper = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  background: #eee;
  width: 100vw;
  height: 100vh;
  z-index: 999;
`;

const MyAppBar = styled((p) => <AppBar {...p} />)`
  padding: 24px;
  font-size: 1.5em;
  display: flex;

`;

const PointDataMobile = () => {
  const { kontrollid } = useParams();
  const { t } = useTranslation();

  const [bounds, setBounds] = useState([
    [46.35877, 8.782379],
    [49.037872, 17.189532]
  ]);
  const [isDialog, setIsDialog] = useState(true);
  const [isPositioning, setIsPositioning] = useState(false);
  const [distance, setDistance] = useState(null); // Distanz zwischen Kontrollpunkt und Live standort
  const [markaPosition, setMarkaPosition] = useState(null); // Position des Kontrollpunktes
  const [pos, setPos] = useState(false); // Position Object zur berechnung vom Livestandort
  const [posid, setPosid] = useState(false);
  const { isLoading, isError, error, data } = useQuery(["kontrollpunkte", kontrollid], async () => {
    //const url = `https://kontrollpunkt.bev.gv.at/api/punkt/${kontrollid}/`;
    const url = `${API_URL}/punkt/${kontrollid}/`;
    const res = await axios.get(url);
    const { phi, lam } = res.data;
    setMarkaPosition([phi, lam]);
    return res.data;
  });

  useEffect(() => {
    if (isPositioning) {
      startWatching();
    } else {
      stopWatching();
    }
  }, [isPositioning]);

  /**
   * Wenn der Positions Watcher läuft wird dieser mit stopWatching beendet.
   */
  const stopWatching = () => {
    navigator.geolocation.clearWatch(posid);
    setPos(false);
    setPosid(false);
    setDistance(null);
    setBounds([
      [46.35877, 8.782379],
      [49.037872, 17.189532]
    ]);
  };

  /**
   * Funktion welche LivePostions veränderungen wahrnimmt und dann dem entsprechend die variablen zur anzeige auf der Karte setzt !
   */
  const startWatching = () => {
    setDistance("-");
    const cPosid = navigator.geolocation.watchPosition(
      (position) => {
        const { coords } = position;
        const { latitude, longitude } = coords;
        const cBounds = [[latitude, longitude], markaPosition];
        const distance = getDistance(cBounds[0], cBounds[1]);

        setPos(coords);
        setDistance(distance);
        setBounds(cBounds);
        //console.log('test', cBounds);
      },
      errorCall,
      watchPositionOptions
    );
    setPosid(cPosid);
  };

  /**
   * Funktion welche beim toggle den Postions Watcher startet oder schließt.
   * @param {*} event
   */
  const toggleIsPositioning = (event) => {
    setIsPositioning(event.target.checked);
  };

  const startPositioningFromDialog = (event) => {
    setIsDialog(false);
    setIsPositioning(event.target.checked);
  };

  if (isLoading) return <LinearProgress color="primary" />;
  if (isError)
    return (
      <AppBar position="static">
        <h3>ES IST EST WAS SCHIEF GEGENAGEN: {error.message}</h3>
      </AppBar>
    );

  return (
    <Wrapper>
      <MyAppBar position="static">
        {data.fullname}
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              checked={isPositioning}
              onChange={toggleIsPositioning}
              value="checkedA"
            />
          }
          label={isPositioning ? t("Positionsbestimmung aktiviert") : t("Positionsbestimmung deaktiviert")}
        />
      </MyAppBar>
      { isPositioning && !pos && <LinearProgress /> }
      <PointMap list={[data]} height={"calc(100% - 75px)"} position={pos} bounds={bounds} distance={roundDist(distance)}></PointMap>
      <Dialog open={isDialog}>
        <DialogTitle>TODO: Abstandsmessung einschalten</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={isPositioning}
                onChange={startPositioningFromDialog}
                value="checkedA"
              />
            }
            label={isPositioning ? t("Positionsbestimmung aktiviert") : t("Positionsbestimmung deaktiviert")}
          />
        </DialogContent>
      </Dialog>
    </Wrapper>
  );
};

export default PointDataMobile;
